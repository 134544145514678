.anvil-canvas {
  container-type: inline-size;
  width: 100%;
  height: 100%;
}

.main-anvil-canvas {
  height: calc(100% - 1rem);
  overflow-y: auto;
}
